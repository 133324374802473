import {getCurrentBrand} from 'configs/brand'
import {RootState} from 'store'

import {
  getOrGenerateAnonymousId,
  persistAnonymousId,
  removeAnonymousIdFromUrl
} from '@daedalus/core/src/utils/anonymousId'
import {getUrlParams} from '@daedalus/core/src/utils/url'
import {getPersistedUserCountryCode} from '@daedalus/core/src/utils/userCountry'
import {getPersistedUserIp} from '@daedalus/core/src/utils/userIp'
import {generateSearchId} from '@findhotel/sapi'

import {MetaState} from './slice'

export const augmentMetaState = (
  initialState: Partial<RootState>
): MetaState => {
  const anonymousId = getOrGenerateAnonymousId()
  persistAnonymousId(anonymousId)
  removeAnonymousIdFromUrl()

  const userIp = getPersistedUserIp()
  const userCountry = getPersistedUserCountryCode()
  const {deviceId, searchId} = getUrlParams() as {
    deviceId: MetaState['deviceId']
    searchId: MetaState['searchId']
  }
  return {
    brandCode: getCurrentBrand().code,
    searchId: searchId || generateSearchId(),
    anonymousId,
    userIp,
    userCountry,
    deviceId,
    // Allow tests to overwrite initial state
    ...(initialState?.meta || {})
  }
}

export {default} from './slice'

import React from 'react'
import {defineMessages, useIntl} from 'react-intl'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {Text} from '@daedalus/atlas/Text'
import {
  getPrioritizedMeals,
  MEAL_AMENITIES
} from '@daedalus/core/src/offer/business/meal'
import {Offer} from '@daedalus/core/src/offer/types/offer'
import {Amenities, RoomOffer} from '@findhotel/sapi'

const roomLabels = defineMessages({
  payLater: {
    id: 'hotelOffer.payLater',
    defaultMessage: 'Pay later'
  },
  allInclusive: {
    id: 'hotelOffer.allInclusive',
    defaultMessage: 'Breakfast included, Lunch included, Dinner included'
  },
  breakfast: {
    id: 'hotelOffer.breakfast',
    defaultMessage: 'Breakfast included'
  },
  lunch: {
    id: 'hotelOffer.lunch',
    defaultMessage: 'Lunch included'
  },
  dinner: {
    id: 'hotelOffer.dinner',
    defaultMessage: 'Dinner included'
  },
  fullBoard: {
    id: 'hotelOffer.fullBoard',
    defaultMessage: 'Full board meal'
  },
  halfBoard: {
    id: 'hotelOffer.halfBoard',
    defaultMessage: 'Half board meal'
  },
  meals: {
    id: 'hotelOffer.meals',
    defaultMessage: 'Breakfast included, Lunch included, Dinner included'
  }
})

type RoomLabelsKeys = keyof typeof roomLabels

interface Props {
  offer: RoomOffer
}

const LabelsContainer = styled.div(
  ({theme}) => css`
    display: flex;
    flex-direction: column;
    color: ${theme.colors.content.featured.c600};
  `
)

const getMealsAmenitiesFromRoomOffer = (
  offer: Pick<Offer, 'services'>
): string[] | Amenities[] => {
  return offer?.services?.filter(service => MEAL_AMENITIES.has(service)) ?? []
}

export const RoomLabels = ({offer}: Props) => {
  const {formatMessage} = useIntl()
  if (!offer) return null

  const {canPayLater} = offer

  const meals = getMealsAmenitiesFromRoomOffer(offer)

  const roomLabelItems = {
    payLater: canPayLater
  }

  type RoomLabelItemsKeys = keyof typeof roomLabelItems

  const translatedRoomLabels = Object.keys(roomLabelItems).reduce(
    (out: string[], key) => {
      if (
        roomLabelItems[key as RoomLabelItemsKeys] &&
        roomLabels[key as RoomLabelsKeys]
      ) {
        return out.concat(formatMessage(roomLabels[key as RoomLabelsKeys]))
      }

      return out
    },
    []
  )
  const translatedMealsLabels = getPrioritizedMeals(meals as Amenities[]).map(
    meal =>
      meal &&
      roomLabels[meal as RoomLabelsKeys] &&
      formatMessage(roomLabels[meal as RoomLabelsKeys])
  )

  const translatedLabels = ([] as string[]).concat(
    translatedRoomLabels,
    translatedMealsLabels
  )

  if (translatedLabels.length <= 0) return null

  return (
    <LabelsContainer>
      {translatedLabels.map(label => (
        <Text variant="bodyS" key={label}>
          {label}
        </Text>
      ))}
    </LabelsContainer>
  )
}

import React from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {SizesType} from 'types/Sizes'
import {ThemeType} from 'types/Theme'

import {linariaMq} from '../../../utils/breakpoints'
import {NoStyleButton} from '../helpers/NoStyleButton'
import {Icon} from '../Icon'

type StepperSize = Extract<SizesType, 'sm' | 'lg'>

interface Props {
  /** Pass through classname to allow styles overrides */
  className?: string
  /** Identify the element for selection in integration tests, FullStory, etc. */
  dataId?: string
  /** ID of this component, passed down by the controlling parent component */
  id: string
  /** The maximum value allowed */
  max?: number
  /** The minimum value allowed */
  min?: number
  /** Name of this component, passed down by the controlling parent component */
  name: string
  /** An onChange callback passed down by the controlling parent component */
  onChange: (value: number) => void
  /** The value passed down by the controlling parent component */
  value: number
  /** The size of the component */
  size?: StepperSize
}

const getButtonSize = (size?: StepperSize) => (size === 'sm' ? 34 : 46)

const withColor = ({theme}: {theme: ThemeType}) => {
  return css`
    background-color: ${theme.colors.stepper.default.background};
    border-width: ${theme.layout.borderWidth.bw100}px;
    border-style: solid;
    border-color: ${theme.colors.stepper.default.border};
    color: ${theme.colors.stepper.default.content};

    ${linariaMq.desktopXs} {
      &:hover {
        background-color: ${theme.colors.stepper.default.hover.background};
        border-color: ${theme.colors.stepper.default.hover.border};
        color: ${theme.colors.stepper.default.hover.content};
      }

      &:focus-visible {
        z-index: 1;
        outline: 1px solid ${theme.colors.border.interactive.c950};
        outline-offset: 1px;
        background-color: ${theme.colors.stepper.default.hover.background};
        color: ${theme.colors.stepper.default.hover.content};
      }
    }

    &:active {
      background-color: ${theme.colors.stepper.default.active.background};
      border-color: ${theme.colors.stepper.default.active.border};
      color: ${theme.colors.stepper.default.active.content};
    }

    &:disabled {
      cursor: not-allowed;
      background-color: ${theme.colors.stepper.disabled.background};
      border-color: ${theme.colors.stepper.disabled.border};
      color: ${theme.colors.stepper.disabled.content};
    }
  `
}

const withBorderRadius = ({theme}: {theme: ThemeType}) => {
  const radius = theme.layout.radius.md
  const borderWidth = theme.layout.borderWidth.bw100
  return css`
    /* @mirror */

    &:first-child {
      border-radius: ${radius}px 0 0 ${radius}px;
      border-width: ${borderWidth}px;
      [dir='rtl'] & {
        border-radius: 0 ${radius}px ${radius}px 0;
      }
    }
    &:last-child {
      border-radius: 0 ${radius}px ${radius}px 0;
      border-width: ${borderWidth}px;
      [dir='rtl'] & {
        border-radius: ${radius}px 0 0 ${radius}px;
      }
    }
  `
}

const StepperContainer = styled('div')`
  display: flex;
  align-items: center;
`

export const StepperButton = styled(NoStyleButton)<{
  size?: StepperSize
}>(
  ({size}) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${getButtonSize(size)}px;
    width: ${getButtonSize(size) - 1}px;
    margin: 0;
    border: 0;
    padding: 0;
    background: none;
    outline: none;
    cursor: pointer;
  `,
  withColor,
  withBorderRadius
)

export const InputElement = styled('input')<{sizeVariant?: StepperSize}>(
  ({theme, sizeVariant}) => css`
    background-color: ${theme.colors.stepper.default.background};
    border-top: ${theme.layout.borderWidth.bw100}px solid
      ${theme.colors.stepper.default.border};
    border-bottom: ${theme.layout.borderWidth.bw100}px solid
      ${theme.colors.stepper.default.border};
    border-right: 0;
    border-left: 0;
    color: ${theme.colors.stepper.default.text};
    outline: none;
    text-align: center;
    height: ${getButtonSize(sizeVariant)}px;
    width: ${getButtonSize(sizeVariant)}px;
    cursor: default;
    border-radius: 0;
    padding: 0;
    ${theme.typography.text.bodyS}
  `
)

const isInRange = (value: number, min: number, max: number) =>
  value >= min && value <= max

export const Stepper = ({
  dataId,
  id,
  min = 0,
  max = 10,
  name,
  size,
  onChange,
  value,
  className
}: Props) => {
  const removeValue = () => {
    const newValue = value - 1

    if (isInRange(newValue, min, max)) {
      onChange(newValue)
    }
  }

  const addValue = () => {
    const newValue = value + 1

    if (isInRange(newValue, min, max)) {
      onChange(newValue)
    }
  }

  return (
    <StepperContainer data-id={dataId} className={className}>
      <StepperButton
        size={size}
        data-id={`StepperMinus`}
        disabled={value <= min}
        onClick={removeValue}
      >
        <Icon name="Minus" size="md" />
      </StepperButton>

      <InputElement
        readOnly
        sizeVariant={size}
        id={id}
        name={name}
        type="text"
        value={value}
      />

      <StepperButton
        size={size}
        data-id={`StepperPlus`}
        disabled={value >= max}
        onClick={addValue}
      >
        <Icon name="Plus" size="md" />
      </StepperButton>
    </StepperContainer>
  )
}

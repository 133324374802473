import {parse, stringify} from 'qs'
import {
  anyPass,
  concat,
  flip,
  isEmpty,
  isNil,
  join,
  map,
  pipe,
  reject
} from 'ramda'

/** @hidden */
type UrlsType = {
  root: string
}

/** @hidden */
const urls: UrlsType = {
  root: '/'
}

export default urls

/**
 *
 * Returns the query params from the `window.location.search` in object structure
 * @returns Object with key-value pairs for each of the parameters in the query
 */

export const getQueryStringObject = () => {
  const queryString = window.location.search

  return parse(queryString, {ignoreQueryPrefix: true})
}

/**
 *
 * Removes specified parameter from provided URL
 * @returns A new URL without the specified parameter
 * @param url the url from which the parameter will be removed
 * @param parameter the chosen parameter to be removed
 */

export const removeURLParameter = (url: string, parameter: string): string => {
  try {
    const urlObj = new URL(url)
    urlObj.searchParams.delete(parameter)

    return urlObj.toString()
  } catch (e) {
    return url
  }
}

/**
 *
 * Parses URL into a detailed object
 * @returns An object with parsed details about the URL
 * @param url the url to be parsed
 */
export const parseUrl = (url: string) => {
  // Let the browser do the work
  const parser = document.createElement('a')
  parser.href = url
  const sanitizedSearchString = parser.search.replace(/^\?/g, '')

  const searchObject = parse(sanitizedSearchString)

  return {
    protocol: parser.protocol,
    host: parser.host,
    hostname: parser.hostname,
    port: parser.port,
    pathname: parser.pathname,
    hash: parser.hash,
    search: sanitizedSearchString,
    searchObject
  }
}

/** @hidden */
const cleanArrayFromFalsies = reject(anyPass([isEmpty, isNil]))

/**
 *
 * Trims all kinds of slashes from the URL
 * @returns A new URL without slashes
 * @param url The url to be trimmed of slashes
 */
export const trimSlashes = (url: string): string =>
  url ? url.replace(/^\/|\/+$/g, '') : ''

/**
 *
 * Ensures that the URL has correct slashes, if not, replaces it
 * @returns A URL which will have the correct slashes
 * @param url the url with correct slashes
 */
export const ensureUrlCorrectSlashes = pipe(
  map(trimSlashes),
  cleanArrayFromFalsies,
  join('/'),
  flip(concat)('/')
)

/**
 *
 * Gets the value of a URL param
 * @returns The value of the specified param
 * @param {object} __namedParameters search: the URL from which you want to get a value and param: The param/key of which you want to get the value
 */
export const getUrlParam = ({
  search,
  param
}: {
  search: string
  param: string
}) => {
  const searchObject = parseQueryString(search)
  return searchObject[param]
}

/**
 *
 * Gets the value of a URL param
 * @returns The value of the specified param
 * @param {object} __namedParameters search: the URL from which you want to check and param: The param/key to be searched for
 */
export const urlHasParam = ({
  search,
  param
}: {
  search: string
  param: string
}) => {
  return Boolean(getUrlParam({search, param}))
}

/**
 * Trim leading `?` and parse
 * @param queryString URL query string. Might contain a leading `?`.
 * @returns An object of parsed query parameters
 */
export const parseQueryString = (
  queryString: string
): ReturnType<typeof parse> => parse(queryString, {ignoreQueryPrefix: true})

/**
 * Converts an url into base 64 and removes unsafe characters
 * @param url URL you want to convert.
 * @returns a base64 string
 */
export const safeBase64Url = (url: string) => {
  return window.btoa(url).replace('+', '-').replace('/', '_').replace(/=+$/, '')
}

/**
 * Return the domain name from the given host name. It will not return subdomain, but only the main domain
 * @param hostName - secure.findhotel.net (as returned by window.location.hostname)
 */
export const getDomainName = (hostName: string) =>
  hostName.slice(
    Math.max(0, hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1) + 1)
  )

/**
 * Check if the string is a valid URL
 * @param url URL you want to validate.
 * @returns a boolean if the url is valid or not
 */
export const isValidURL = (url: string) => {
  try {
    return Boolean(new URL(url))
  } catch {
    return false
  }
}

export const getUrlParams = () =>
  parse(location?.search, {
    ignoreQueryPrefix: true
  })

/**
 * Converts the passed object to the URL query string
 * nulls and undefined values are skipped
 * @param query - Query params as an object.
 * @returns a URL query string
 */
export const stringifyUrlObject = <T extends object>(query: T) =>
  stringify(query, {
    arrayFormat: 'repeat',
    encodeValuesOnly: true,
    skipNulls: true
  })

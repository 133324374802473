import React, {ReactNode} from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {ThemeType} from 'types/Theme'

import {linariaMq} from '../../../utils/breakpoints'
import {
  baseLayout,
  defaultBorderStyles,
  focusBorderStyles,
  focusShadowStyles,
  InputBaseSize,
  StartIconsWrapper,
  TextTransform,
  withBackground,
  withBorder,
  withDisabled,
  withIconPadding,
  withInvalidState,
  withRadius,
  withShadows,
  withSize,
  withTextTransform,
  withTypography
} from '../helpers/InputBase'
import {NoStyleButton} from '../helpers/NoStyleButton'

type Props = {
  /** Identify the element for selection in integration tests, FullStory, etc. */
  dataId?: string
  /** Whether the input is disabled */
  disabled?: boolean
  /** An Icon to display on the left side of the input */
  icon?: JSX.Element
  /** ID of this component, passed down by the controlling parent component */
  id: string
  /** Whether the input is rounded */
  isRounded?: boolean
  /** An onClick callback passed down by the controlling parent component */
  onClick?: (event: React.SyntheticEvent<Element, Event>) => void
  /** Content to be used as the input placeholder */
  placeholder?: ReactNode
  /** The size of the input */
  size?: InputBaseSize
  /** HTML class to be include in the button */
  className?: string
  /** The content of the InputButton, a react component or pure text */
  children: ReactNode
}

const withPlaceholder = ({
  theme,
  hasPlaceholder
}: {
  theme: ThemeType
  hasPlaceholder: boolean
}) =>
  hasPlaceholder &&
  css`
    color: ${theme.colors.input.default.placeholder};
  `

const ButtonElement = styled(NoStyleButton)(
  ({theme}) => css`
    width: 100%;
    position: relative;

    &:focus ${InputButtonStyledContainer} {
      ${defaultBorderStyles(theme)};
    }

    ${linariaMq.desktopXs} {
      &:focus ${InputButtonStyledContainer} {
        ${focusShadowStyles(theme)};
        ${focusBorderStyles(theme)};
      }
    }
  `
)

// This component name is used in the ButtonGroup component
export const InputButtonStyledContainer = styled.div<{
  hasPlaceholder: boolean
  isRounded?: boolean
  disabled?: boolean
  sizeVariant: InputBaseSize
  withStartIcon: boolean
  textTransformType?: TextTransform
}>(
  baseLayout,
  withSize,
  withBackground,
  withInvalidState,
  withShadows,
  withIconPadding,
  withRadius,
  withDisabled,
  withTextTransform,
  withTypography,
  withPlaceholder,
  withBorder,
  () => css`
    z-index: 0;
    padding-right: 0;
    cursor: pointer;
    height: 100%;
  `
)

export const InputButton = ({
  dataId,
  disabled,
  icon,
  id,
  isRounded,
  onClick,
  placeholder,
  size = 'lg',
  className,
  children
}: Props) => (
  <ButtonElement onClick={onClick} className={className} data-id={dataId}>
    <InputButtonStyledContainer
      isRounded={isRounded}
      disabled={disabled}
      id={id}
      sizeVariant={size}
      withStartIcon={Boolean(icon)}
      hasPlaceholder={!children}
    >
      {children ? children : placeholder}
    </InputButtonStyledContainer>
    {icon && <StartIconsWrapper sizeVariant={size}>{icon}</StartIconsWrapper>}
  </ButtonElement>
)

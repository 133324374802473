import addDays from 'date-fns/addDays'
import differenceInDays from 'date-fns/differenceInDays'
import {append, identity, max, min, pick, reduce, sortBy} from 'ramda'
import {ThemeType} from 'types/Theme'

import {AvailabilityHotelEntity} from '../../availability/types'
import {
  dateStringToMiddayDate,
  dateToMiddayDate,
  ensureCheckInOnOrAfterToday
} from '../date'

export const INITIAL_FETCH_WAIT_TIME = 2000 // ms
export const INITIAL_FETCH_WAIT_TIME_FOR_NO_DATES_TRAFFIC = 0 // ms
const SEARCH_RANGE = 14 // days
export const MAX_DATES_TO_SHOW_DESKTOP = 9 // days
export const MAX_DATES_TO_SHOW_MOBILE = 4 // days

export enum PRICE_TYPE {
  HIGH = 'high',
  NEUTRAL = 'neutral',
  LOW = 'low'
}

export const getPriceTypeCalendarBackgroundColor = (
  priceType: PRICE_TYPE,
  theme: ThemeType
) => {
  const colors = {
    [PRICE_TYPE.HIGH]: theme.colors.background.accent.c050,
    [PRICE_TYPE.NEUTRAL]: theme.colors.background.neutral.c000,
    [PRICE_TYPE.LOW]: theme.colors.background.featured.c050
  }

  return colors[priceType]
}

export const getPriceTypeCalendarColor = (
  priceType: PRICE_TYPE,
  theme: ThemeType
) => {
  const colors = {
    [PRICE_TYPE.HIGH]: theme.colors.background.accent.c600,
    [PRICE_TYPE.NEUTRAL]: theme.colors.background.neutral.c950,
    [PRICE_TYPE.LOW]: theme.colors.background.featured.c700
  }

  return colors[priceType]
}

const getPrices = (hotelAvailabilityPrices: AvailabilityHotelEntity) => {
  const prices = Object.keys(hotelAvailabilityPrices).map(date => {
    return hotelAvailabilityPrices[date]?.cheapestNightlyRate
  })
  return prices
}

const getMinMaxPrices = (
  hotelAvailabilityPrices: AvailabilityHotelEntity
): {minPrice: number; maxPrice: number} => {
  const prices = getPrices(hotelAvailabilityPrices)
  const minPrice = reduce(min, Infinity, prices) as number
  const maxPrice = reduce(max, -Infinity, prices) as number
  return {minPrice, maxPrice}
}

/**
 * Percentile = ((L + ( 0.5 x S )) / N) * 100
 * L = Number of values lower than value
 * S = Number of same rank
 * N = Total numbers
 */
const percentile = (array: number[], value: number) => {
  const currentIndex = 0
  const totalCount = array.reduce((count, currentValue) => {
    const roundedValue = Math.round(value)
    const roundedCurrentValue = Math.round(currentValue)

    if (roundedCurrentValue < roundedValue) {
      return count + 1 // add 1 to `count`
    } else if (roundedCurrentValue === roundedValue) {
      return count + 0.5 // add 0.5 to `count`
    }
    return count + 0
  }, currentIndex)
  return (totalCount * 100) / array.length
}

const CHEAP_PRICE_PERCENTILE = 25
const EXPENSIVE_PRICE_PERCENTILE = 75

const getStringDateMonth = (date: string) => date.substring(5, 7)

const getCurrentMonthPrices = (
  currentDate: string,
  hotelAvailabilityPrices: AvailabilityHotelEntity
) => {
  const currentMonth = getStringDateMonth(currentDate)

  return Object.keys(hotelAvailabilityPrices).reduce((acc: number[], d) => {
    if (currentMonth === getStringDateMonth(d)) {
      return append(hotelAvailabilityPrices[d]?.cheapestNightlyRate, acc)
    }
    return acc
  }, [])
}

export const calculatePriceType = (
  hotelAvailabilityPrices: AvailabilityHotelEntity,
  price: number,
  date: string
) => {
  const monthPrices = getCurrentMonthPrices(date, hotelAvailabilityPrices)
  const percentileValue = percentile(monthPrices, price)

  const priceType =
    percentileValue < CHEAP_PRICE_PERCENTILE
      ? PRICE_TYPE.LOW
      : percentileValue < EXPENSIVE_PRICE_PERCENTILE
        ? PRICE_TYPE.NEUTRAL
        : PRICE_TYPE.HIGH

  return priceType
}

export const getIsCheapestPrice = (
  hotelAvailabilityPrices: AvailabilityHotelEntity,
  price: number,
  dates?: string[]
) => {
  const selectedDatesAvailabilityPrices = dates
    ? pick(dates, hotelAvailabilityPrices)
    : hotelAvailabilityPrices
  const {minPrice} = getMinMaxPrices(selectedDatesAvailabilityPrices)

  return Boolean(price === minPrice)
}

export const getCheckOutDate = (checkIn: string, numberOfNights: number) => {
  const checkInDate = dateStringToMiddayDate(checkIn)
  const checkOutDate = new Date(checkInDate)
  checkOutDate.setDate(checkInDate.getDate() + numberOfNights)
  return checkOutDate
}

const sortAlphabetically = sortBy(identity)

/**
 * Returns the dates to display in the availability pane.
 * Pick dates before and after the check in date, if there are enough available dates.
 * Otherwise, pick dates after the check in date.
 * @param hotelAvailabilityPrices - available prices for the hotel
 * @param checkIn - check in date
 * @param maxDatesToShow - maximum number of dates to show
 * @returns string[] - dates to display
 */
export const getDatesToDisplay = (
  hotelAvailabilityPrices: AvailabilityHotelEntity,
  checkIn: string,
  maxDatesToShow: number
): string[] => {
  const hotelAvailabilityDates = Object.keys(hotelAvailabilityPrices)
  const dates = [...new Set([...hotelAvailabilityDates, checkIn])]
  const sortedDates = sortAlphabetically(dates)
  const checkInIndex = sortedDates.findIndex(d => d === checkIn)
  const datesToDisplay: string[] = []

  Array.from(Array(maxDatesToShow), (_, i) => {
    const index = i + 1
    const nextDate = sortedDates[checkInIndex + index]
    const previousDate = sortedDates[checkInIndex - index]

    // add half the dates before the check in date, if there are enough available dates
    if (previousDate && datesToDisplay.length <= maxDatesToShow / 2) {
      datesToDisplay.push(previousDate)
    }
    // and fill the rest of the array with dates after the check in date
    if (nextDate && datesToDisplay.length < maxDatesToShow) {
      datesToDisplay.push(nextDate)
    }
  })

  return sortAlphabetically(datesToDisplay)
}

export const getAvailabilityStatus = ({
  hotelAvailabilityPrices,
  date,
  searchComplete
}: {
  hotelAvailabilityPrices?: AvailabilityHotelEntity
  date: string
  searchComplete: boolean
}) => {
  const isDateAvailable =
    hotelAvailabilityPrices && Boolean(hotelAvailabilityPrices[date])
  if (isDateAvailable)
    return calculatePriceType(
      hotelAvailabilityPrices,
      hotelAvailabilityPrices[date]?.cheapestNightlyRate,
      date
    )
  if (!searchComplete) return 'loading'
  return 'unavailable'
}

/**
 * Returns the initial search dates, based on the check in date.
 * If the check in date is further ahead than today + SEARCH_RANGE,
 * the search dates will be centered around the check in date.
 * Otherwise, the search dates will be from today.
 * @param checkIn
 * @returns {{startDate: Date, endDate: Date}}
 */
export const getInitialSearchDates = (checkIn: string) => {
  let startDate = null
  let endDate = null

  const today = dateToMiddayDate(new Date())
  const isCheckInPastMidSearchRange =
    differenceInDays(new Date(checkIn), addDays(today, SEARCH_RANGE)) > 0

  if (isCheckInPastMidSearchRange) {
    startDate = ensureCheckInOnOrAfterToday(
      addDays(new Date(checkIn), -SEARCH_RANGE / 2)
    )
    endDate = addDays(new Date(checkIn), SEARCH_RANGE / 2)
  } else {
    startDate = today
    endDate = addDays(today, SEARCH_RANGE)
  }

  return {
    startDate,
    endDate
  }
}

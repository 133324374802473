import {useDispatch, useSelector} from 'react-redux'

import {useBrand} from '../../_web/brand/hooks/useBrand'
import {selectUserId} from '../../auth/modules/selectors'
import {getAnonymousId} from '../../utils/anonymousId'
import {trackEvent} from '../modules/actions'
import {customerIo} from '../services/CustomerIo'
import {Action, Category, Entity, Team} from '../types/Events'

export enum MenuItemComponent {
  Account = 'Account',
  GemsMembership = 'GemsMembership',
  Referral = 'Referral',
  MyBookings = 'MyBookings',
  Currency = 'Currency',
  Language = 'Language',
  MyWatchlists = 'MyWatchlists',
  GetHelp = 'GetHelp',
  ShareYourFeedback = 'ShareYourFeedback',
  PrivacyPolicy = 'PrivacyPolicy',
  ManageNotifications = 'ManageNotifications'
}

export const useTrackMenuItemClick = () => {
  const dispatch = useDispatch()
  const {brand} = useBrand()
  const cio = customerIo(brand)
  const anonymousId = getAnonymousId() as string
  const userId = useSelector(selectUserId)

  const trackMenuItemClick = (component: MenuItemComponent, team: Team) => {
    dispatch(
      trackEvent({
        category: Category.User,
        entity: Entity.MenuItem,
        action: Action.Clicked,
        component,
        team
      })
    )

    if (component === MenuItemComponent.ShareYourFeedback) {
      cio.trackEvent({
        name: `${Category.User}_${Entity.MenuItem}_${Action.Clicked}`,
        anonymousId,
        userId: userId,
        component
      })
    }
  }

  return {trackMenuItemClick}
}

import {QueryStatus} from '@reduxjs/toolkit/dist/query'
import {getIsCardNotSupported} from 'components/PaymentErrorMessages/CardNotSupported'
import {getIsConnectionError} from 'components/PaymentErrorMessages/ConnectionError'
import {getIsDuplicateBooking} from 'components/PaymentErrorMessages/DuplicateBooking'
import {getIsGenericFraudDeclined} from 'components/PaymentErrorMessages/GenericFraudDeclined'
import {getIsGenericPaymentDeclined} from 'components/PaymentErrorMessages/GenericPaymentDeclined'
import {getIsInsufficientFunds} from 'components/PaymentErrorMessages/InsufficientFunds'
import {getIsInvalidEmail} from 'components/PaymentErrorMessages/InvalidEmail'
import {getIsPaymentNotAuthorized} from 'components/PaymentErrorMessages/PaymentNotAuthorized'
import {getIsPriceMismatch} from 'components/PaymentErrorMessages/PriceMismatch'
import {getIsWrongCCError} from 'components/PaymentErrorMessages/WrongCCNumber'
import {getIsWrongCVCError} from 'components/PaymentErrorMessages/WrongCVCCode'
import {getIsGenericSupplierOrBackendError} from 'components/SupplierErrorMessages/GenericSupplierOrBackendError'
import {getOfferCheckError} from 'modules/offerCheck/selectors'
import {
  getModuleState as getRoomModuleState,
  getRooms,
  getSplitBooking
} from 'modules/rooms/selectors'
import {roomsFetchDone, roomsFetchError} from 'modules/rooms/slice'
import {anyPass, curry, path, propOr} from 'ramda'
import {RootState} from 'store'
import {CommonErrorPayloadFormatType} from 'types/errorResponse'

import {Category as ErrorCategory} from '@daedalus/core/src/api-types/bovio/response/error'
import {ErrorResponse} from '@daedalus/core/src/booking/types/ErrorResponse'

import {ERROR} from './actions/error'
import {
  BOOKING_PRESENTER_ERRORS,
  FETCH_ROOMS_PRESENTER_ERRORS,
  PRESENTER_TYPES,
  PresenterTypesType
} from './constants'

export type ErrorType = {
  errorResponse: ErrorResponse
  originalErrorAction: string
}

export const getModuleState = (state: RootState) => state?.common?.moduleState

/**
 * Check whether any sold out type error happened in any API
 * Note this does not care about which API parameters were used, it is a global check
 */
export const didAnySoldOutErrorHappen = (
  state: RootState,
  isMobile: boolean
) => {
  if (getModuleState(state) === ERROR) {
    return state?.common?.error?.errorResponse?.details?.category === 'sold_out'
  }

  if (
    getRoomModuleState(state) === roomsFetchDone.type ||
    getRoomModuleState(state) === QueryStatus.fulfilled
  ) {
    const splitBooking = getSplitBooking(state, isMobile)
    const rooms = getRooms(state)

    return rooms.length === 0 && !splitBooking
  }

  return state?.common?.error?.errorResponse?.details?.category === 'sold_out'
}

const testNewArchitectureErrors = anyPass([
  getIsWrongCVCError,
  getIsWrongCCError,
  getIsGenericPaymentDeclined,
  getIsGenericFraudDeclined,
  getIsPaymentNotAuthorized,
  getIsInsufficientFunds,
  getIsInvalidEmail,
  getIsCardNotSupported,
  getIsGenericSupplierOrBackendError,
  getIsConnectionError,
  getIsDuplicateBooking,
  getIsPriceMismatch
])

export const getOriginError = path(['originalError'])

export const getError = path<CommonErrorPayloadFormatType>(['common', 'error'])

export const getErrorByAction = (action?: string) => {
  if (action === roomsFetchError.type) {
    return FETCH_ROOMS_PRESENTER_ERRORS
  }

  return BOOKING_PRESENTER_ERRORS
}

const getPresenterID = (error?: CommonErrorPayloadFormatType | null) => {
  switch (error?.errorResponse?.type) {
    case 'provider':
    case 'provider_booking_creation':
    case 'provider_pre_book': {
      return propOr(
        PRESENTER_TYPES.GenericError,
        error.errorResponse.details.category as ErrorCategory,
        getErrorByAction(error.originalErrorAction)
      )
    }

    case 'booking_unauthorized': {
      return propOr(
        PRESENTER_TYPES.UnauthorizedBookingError,
        error.errorResponse.details.category as ErrorCategory,
        getErrorByAction(error.originalErrorAction)
      )
    }

    case 'input_validation': {
      return propOr(
        PRESENTER_TYPES.GenericError,
        error.errorResponse.type,
        getErrorByAction(error.originalErrorAction)
      )
    }

    case 'pci_proxy': {
      return PRESENTER_TYPES.PopUpPaymentError
    }

    case 'fatal': {
      return PRESENTER_TYPES.GenericError
    }

    default: {
      return propOr(
        PRESENTER_TYPES.GenericError,
        error?.errorResponse?.details.category as ErrorCategory,
        getErrorByAction(error?.originalErrorAction)
      )
    }
  }
}

const hasBackendResponse = (state: RootState) =>
  Boolean(path(['common', 'error', 'errorResponse'], state))

// @Todo - Refactor it after website-2 since now on B side we have
// just one presenter for all Overlay Errors
export const getErrorByPresenterType = curry(
  (presenter: PresenterTypesType, state: RootState) => {
    if (hasBackendResponse(state)) {
      /**
       * Bypass to show the popup error for new error UI
       */
      if (
        !getOfferCheckError(state) &&
        testNewArchitectureErrors({
          data: state.common.error?.errorResponse,
          status: state.common.error?.statusCode
        })
      ) {
        return undefined
      }

      const currentPresenter = getPresenterID(state.common.error)
      if (currentPresenter === presenter) {
        return state.common.error
      }
    }
  }
)

export const getHttpRequestId = (state: RootState) =>
  state.common?.httpRequestId

export const getIsSearchBoxVisible = (state: RootState) =>
  state.common.isSearchBoxVisible

export const getIsOfferCheckAvailable = (state: RootState) =>
  state.common.isOfferCheckModalVisible

import {useEffect} from 'react'
import {useDispatch} from 'react-redux'

import {
  experimentVariationsStore,
  useOpticks
} from '../../experiments/components/OpticksProvider'
import {getCookie} from '../../utils/cookies'
import {getDataDogRumInstance} from '../components/DataDogClient'
import {trackExperimentServed} from '../modules/actions'

const EXPERIMENT_ID = 'aa-server-side-experiment'
const SOURCE_COOKIE_ID = 'X-Source'

export enum ExperimentPackageName {
  Search = 'search',
  Checkout = 'checkout',
  BookingManagement = 'booking-management'
}

/**
 *
 * @param cookieValue a string with the format package:experimentSide
 * @returns object
 */
const extractPackageConfigurationFromCookieValue = (
  cookieValue: string | undefined,
  packageName: ExperimentPackageName
) => {
  //Separating the packages
  const packageValues = cookieValue?.split(',') || []

  return packageValues.reduce((previousValue, currentValue) => {
    //Separating each parameter of the package
    const packageParameters = currentValue.split(':')
    const packageId = packageParameters[0]?.trim()
    if (packageName === packageId) {
      return packageParameters[1]
    }
    return previousValue
  }, '')
}

/**
 * This hook will track the experiments running on the infrastructure layer.
 * @returns null
 */
export const useTrackServerABTestSource = (
  packageName: ExperimentPackageName
) => {
  const dispatch = useDispatch()
  const Opticks = useOpticks()

  const xSource = getCookie(SOURCE_COOKIE_ID)

  const experimentSide = extractPackageConfigurationFromCookieValue(
    xSource,
    packageName
  )
  const isActiveExperimentSide = experimentSide === 'experiment'

  useEffect(() => {
    if (Opticks && experimentSide) {
      dispatch(
        trackExperimentServed(EXPERIMENT_ID, isActiveExperimentSide ? 'b' : 'a')
      )

      getDataDogRumInstance().addFeatureFlagEvaluation(
        EXPERIMENT_ID,
        isActiveExperimentSide
      )

      experimentVariationsStore.add(
        EXPERIMENT_ID,
        isActiveExperimentSide ? 'b' : 'a'
      )
    }
  }, [Opticks, experimentSide])

  return null
}

import React, {ReactElement, SyntheticEvent} from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {linariaMq} from '../../../utils/breakpoints'
import {NoStyleLink} from '../helpers/NoStyleLink'

type DropDownMenuLinkProps = {
  children: React.ReactNode
  /** Pass through classname to allow styles overrides */
  className?: string
  url?: string
  icon?: ReactElement
  onClick?: (e: SyntheticEvent) => void
  /** Identify the element for selection in integration tests, FullStory, etc. */

  dataId?: string
}

const LinkElement = styled(NoStyleLink)(
  ({theme}) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${theme.layout.spacing.s500}px;
    ${theme.typography.text.labelS};
    color: ${theme.colors.content.neutral.c950};
    text-decoration: none;
    gap: ${theme.layout.spacing.s300}px;

    &:first-child {
      border-radius: ${theme.layout.radius.md}px ${theme.layout.radius.md}px 0 0;
    }
    &:last-child {
      border-radius: 0 0 ${theme.layout.radius.md}px ${theme.layout.radius.md}px;
    }

    ${linariaMq.desktopXs} {
      &:hover {
        background: ${theme.colors.background.neutral.c050};
      }

      &:focus {
        background: ${theme.colors.background.neutral.c100};
      }
    }

    &:active {
      background: ${theme.colors.background.neutral.c000};
    }
  `
)

const IconWrapper = styled('div')(
  () => css`
    display: flex;
  `
)

export const DropDownMenuLink = ({
  children,
  url = '#',
  icon,
  className,
  onClick,
  dataId
}: DropDownMenuLinkProps) => {
  return (
    <LinkElement
      data-Id={dataId}
      href={url}
      onClick={onClick}
      className={className}
    >
      {icon && <IconWrapper>{icon}</IconWrapper>}
      {children}
    </LinkElement>
  )
}

import * as React from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {RangeSizesType} from 'types/Sizes'

import {cssTheme} from '../../../../themes'
import {linariaMq} from '../../../../utils/breakpoints'

interface PageWidthContainerPropsType {
  /** The page content */
  children: React.ReactNode
  /** A class name that can be given to the container */
  className?: string
  /** The max width the page will be refined to. If not supplied it is taken from the theme file */
  maxPageWidth?: number
  /** Whether to span the container full width - this will override maxPageWidth */
  isFullWidth?: boolean
  /** The page padding at the desktop-sm breakpoint. Only spacing sizes from the theme are accepted */
  paddingDesktopSmSize?: RangeSizesType
  /** The page padding at the desktop-xs breakpoint. Only spacing sizes from the theme are accepted */
  paddingDesktopXsSize?: RangeSizesType
  /** The page padding at the desktop-md breakpoint. Only spacing sizes from the theme are accepted */
  paddingDesktopMdSize?: RangeSizesType
  /** The page padding at the desktop-lg breakpoint. Only spacing sizes from the theme are accepted */
  paddingDesktopLgSize?: RangeSizesType
  /** The page padding at the mobile-sm breakpoint. Only spacing sizes from the theme are accepted */
  paddingMobileSmSize?: RangeSizesType
  /** The page padding at the mobile-lg breakpoint. Only spacing sizes from the theme are accepted */
  paddingMobileLgSize?: RangeSizesType
}

type PageWidthContainerElementPropsType = Omit<
  PageWidthContainerPropsType,
  'children' | 'className'
>

const PageWidthContainerElement =
  styled.div<PageWidthContainerElementPropsType>(
    ({
      isFullWidth = false,
      maxPageWidth,
      paddingDesktopLgSize,
      paddingDesktopMdSize,
      paddingDesktopSmSize,
      paddingDesktopXsSize,
      paddingMobileSmSize,
      paddingMobileLgSize
    }) => css`
      max-width: ${isFullWidth
        ? '100%'
        : maxPageWidth
          ? `${maxPageWidth}px`
          : cssTheme.layout.maxPageWidth};
      margin: 0 auto;
      padding: 0 ${cssTheme.layout.spacing[paddingMobileSmSize || 's000']};
      ${linariaMq.desktopXs} {
        width: 100%;
      }
      ${paddingMobileLgSize &&
      css`
        ${linariaMq.mobileLg} {
          padding: 0 ${cssTheme.layout.spacing[paddingMobileLgSize]};
        }
      `}
      ${paddingDesktopXsSize &&
      css`
        ${linariaMq.desktopXs} {
          padding: 0 ${cssTheme.layout.spacing[paddingDesktopXsSize]};
        }
      `}
      ${paddingDesktopSmSize &&
      css`
        ${linariaMq.desktopSm} {
          padding: 0 ${cssTheme.layout.spacing[paddingDesktopSmSize]};
        }
      `}
      ${paddingDesktopMdSize &&
      css`
        ${linariaMq.desktopMd} {
          padding: 0 ${cssTheme.layout.spacing[paddingDesktopMdSize]};
        }
      `}
      ${paddingDesktopLgSize &&
      css`
        ${linariaMq.desktopLg} {
          padding: 0 ${cssTheme.layout.spacing[paddingDesktopLgSize]};
        }
      `}
    `
  )

export const PageWidthContainer = ({
  children,
  className,
  isFullWidth,
  maxPageWidth,
  paddingDesktopLgSize,
  paddingDesktopMdSize,
  paddingDesktopSmSize,
  paddingDesktopXsSize,
  paddingMobileSmSize,
  paddingMobileLgSize
}: PageWidthContainerPropsType) => (
  <PageWidthContainerElement
    className={className}
    maxPageWidth={maxPageWidth}
    isFullWidth={isFullWidth}
    paddingDesktopLgSize={paddingDesktopLgSize}
    paddingDesktopMdSize={paddingDesktopMdSize}
    paddingDesktopSmSize={paddingDesktopSmSize}
    paddingDesktopXsSize={paddingDesktopXsSize}
    paddingMobileSmSize={paddingMobileSmSize}
    paddingMobileLgSize={paddingMobileLgSize}
  >
    {children}
  </PageWidthContainerElement>
)

import {toggle} from 'opticks'
interface ConstructOfferCheckQueryParams {
  offerLink: string
  searchId: string | null
  isMobile: boolean
  label: string
  userCountry?: string
  userIp?: string
  anonymousId?: string
  redirectId?: string
}
export const constructOfferCheckQueryParams = ({
  offerLink,
  searchId,
  userCountry,
  userIp,
  isMobile,
  anonymousId,
  redirectId,
  label
}: ConstructOfferCheckQueryParams) => {
  try {
    const {userAgent} = window.navigator
    const deviceType = isMobile ? 'mobile' : 'desktop'
    const offerLinkParams = Object.fromEntries(new URL(offerLink).searchParams)

    const params: Record<string, string | undefined | null> = toggle(
      'ebed98a4-update-bovio-endpoints',
      {
        anonymousId,
        userAgent,
        deviceType,
        searchId,
        userCountry,
        userIp,
        redirectId,
        label,
        ...offerLinkParams
      },
      {
        anonymousId,
        deviceType,
        searchId,
        redirectId,
        label,
        ...offerLinkParams
      }
    )
    const filteredParams: Record<string, string> = Object.fromEntries(
      Object.entries(params).filter(
        ([, value]) => value !== null && value !== undefined
      )
    ) as Record<string, string>

    const queryString = new URLSearchParams(filteredParams).toString()
    /**
     * Example URL:
     * https://bofh-api.bofh-staging.fih.io/v2/offers/deal-freeze/723cce0c-fabc-4d65-a04d-29b105019524/check?anonymousId=67125316-b16d-4536-b809-76eedf11dca7&(rest of params)
     */
    return {params, queryString}
  } catch (e) {
    console.error(e)
    return {params: {}, queryString: ''}
  }
}

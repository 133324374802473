import {toggle} from 'opticks'

import {useGetBoVioConfiguration} from './useGetBoVioConfiguration'
interface Props {
  boVioUrl: string
  boVioUrlCloudfront: string
  // BoVio API key
  boVioProfile: string
}
export const GetBoVioConfiguration = ({
  boVioUrl,
  boVioUrlCloudfront,
  boVioProfile
}: Props) => {
  const endpoint = toggle(
    'ebed98a4-update-bovio-endpoints',
    boVioUrl,
    boVioUrlCloudfront
  )
  useGetBoVioConfiguration({boVioUrl: endpoint, boVioProfile})
  return null
}

export const DISPLAY_REVIEWS_COLLECTOR = 'DISPLAY_REVIEWS_COLLECTOR'
export const HIDE_REVIEWS_COLLECTOR = 'HIDE_REVIEWS_COLLECTOR'

export const displayReviewsCollector = () => ({
  type: DISPLAY_REVIEWS_COLLECTOR
})

export const hideReviewsCollector = () => ({
  type: HIDE_REVIEWS_COLLECTOR
})

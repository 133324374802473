import {createAction} from '@reduxjs/toolkit'

import {SapiClient} from '@findhotel/sapi'

import {OldHotelOfferEntity} from '../../../offer/business/offersMapping'
import {BaseGetRoomsParams, GetSearchParams, SearchData} from '.'
import {SearchApiError} from './error'

interface OfferSearchPayloadBase {
  parameters: Parameters<SapiClient['offers']>[0]
  searchId: string
}

// Note: these events could be refactored to simply have `OffersData` as the payload to simplify everything
// See search actions with `SearchData` below
interface OfferSearchStartedPayload extends OfferSearchPayloadBase {
  checkIn: string
  checkOut: string
  rooms?: string
}

type OfferSearchReceivedPayload = OfferSearchPayloadBase

interface OfferSearchSucceededPayload extends OfferSearchPayloadBase {
  offers?: OldHotelOfferEntity
}

interface OfferSearchErroredPayload extends OfferSearchPayloadBase {
  error?: SearchApiError
}

interface RoomSearchSucceededPayload {
  params: BaseGetRoomsParams
}

export const offersSearchStarted = createAction<OfferSearchStartedPayload>(
  'searchApi/offersSearchStarted'
)
export const offersSearchReceived = createAction<OfferSearchReceivedPayload>(
  'searchApi/offersSearchRecieved'
)
export const offersSearchSucceeded = createAction<OfferSearchSucceededPayload>(
  'searchApi/offersSearchSucceeded'
)

export const offersSearchErrored = createAction<OfferSearchErroredPayload>(
  'searchApi/offerSearchErrored'
)

export const roomSearchStarted = createAction('searchApi/roomSearchStarted')
export const roomSearchSucceeded = createAction<RoomSearchSucceededPayload>(
  'searchApi/roomSearchSucceeded'
)

interface SearchEventPayload {
  parameters: GetSearchParams
  data: SearchData
}

interface SearchErroredPayload {
  parameters: GetSearchParams
  data: SearchData | undefined
  error: SearchApiError
}

export const searchStarted = createAction<SearchEventPayload>(
  'searchApi/searchStarted'
)
export const searchAnchorReceived = createAction<SearchEventPayload>(
  'searchApi/searchAnchorReceived'
)
export const searchHotelsReceived = createAction<SearchEventPayload>(
  'searchApi/searchHotelsReceived'
)
export const searchOffersReceived = createAction<SearchEventPayload>(
  'searchApi/searchOffersReceived'
)
export const searchCompleted = createAction<SearchEventPayload>(
  'searchApi/searchCompleted'
)
export const searchErrored = createAction<SearchErroredPayload>(
  'searchApi/searchErrored'
)

export type SearchApiGetSearchActionsType =
  | typeof searchStarted
  | typeof searchAnchorReceived
  | typeof searchHotelsReceived
  | typeof searchOffersReceived
  | typeof searchCompleted

export type SearchApiActionsType =
  | typeof offersSearchStarted
  | typeof offersSearchReceived
  | typeof offersSearchSucceeded
  | typeof offersSearchErrored
  | typeof roomSearchStarted
  | typeof roomSearchSucceeded
  | SearchApiGetSearchActionsType

import React, {ReactNode} from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {ThemeType} from 'types/Theme'

import {hexToRGBAString} from '@daedalus/core/src/utils/string'

import {linariaMq} from '../../../utils/breakpoints'
import {Text, TextVariant} from '../Text'

interface Props {
  /** Pass through className to allow styles overrides */
  className?: string
  /** Identify the element for selection in integration tests, FullStory, etc. */
  dataId?: string
  /** Whether the radio is checked or not */
  checked: boolean
  /** Whether the radio is disabled or not */
  disabled?: boolean
  /** Whether the radio container should render full width */
  fullWidth?: boolean
  /** Whether the input has an error */
  hasError?: boolean
  /** Content to render in label next to the radio */
  label?: ReactNode
  /** Name used for html input element */
  name: string
  /** The Radio change handler. Fires when user change state of Radio */
  onChange?: (e: React.SyntheticEvent) => void
  /** Value used for html input element */
  value?: string
  /** Use larger touch area */
  hasTouchArea?: boolean
  /** Label size */
  labelSize?: Extract<TextVariant, 'bodyM' | 'bodyS'>
}

const RADIO_SIZE = 20
const RADIO_DOT_SIZE = 10
const LABEL_MARGIN_LEFT = 10
const TOUCHAREA_SIZE_SM = 2
const TOUCHAREA_SIZE_LG = 12

const RadioElement = styled.div`
  display: flex;
`

export const RadioIcon = styled.div(
  ({theme}) => css`
    position: relative;
    width: ${RADIO_SIZE}px;
    min-width: ${RADIO_SIZE}px;
    height: ${RADIO_SIZE}px;
    background-color: ${theme.colors.inputControls.default.background};
    border: 1px solid ${theme.colors.inputControls.default.border};
    border-radius: ${theme.layout.radius.rounded}px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0px 2px 4px
      ${hexToRGBAString(theme.colors.inputControls.default.shadow, 0.05)};
    cursor: pointer;

    &:after {
      content: '';
      width: ${RADIO_DOT_SIZE}px;
      height: ${RADIO_DOT_SIZE}px;
      background-color: ${theme.colors.inputControls.default.background};
      border-radius: ${theme.layout.radius.rounded}px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale(0);
    }
  `
)

export const RadioInput = styled.input(
  ({theme}) => css`
    display: block;
    position: absolute;
    top: 0;
    left: ${RADIO_SIZE + TOUCHAREA_SIZE_LG}px;
    opacity: 0;
    width: 0;
    height: 0;

    ${linariaMq.desktopXs} {
      &:focus-visible + ${RadioIcon} {
        box-shadow:
          0 0 0 1px ${theme.colors.inputControls.default.focus.border},
          0 0 0 3px ${theme.colors.inputControls.default.focus.shadow};

        &:before {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    &:active:not(:disabled) {
      & + ${RadioIcon} {
        background-color: ${theme.colors.inputControls.default.active
          .background};
        border-color: ${theme.colors.inputControls.default.active.border};
        box-shadow: 0 0 0 2px
          ${theme.colors.inputControls.default.active.shadow};
      }

      &:checked + ${RadioIcon} {
        background-color: ${theme.colors.inputControls.checked.active
          .background};
        border-color: ${theme.colors.inputControls.checked.active.border};
        box-shadow: 0 0 0 2px
          ${theme.colors.inputControls.checked.active.shadow};
      }

      &[data-field-error='true'] + ${RadioIcon} {
        background-color: ${theme.colors.inputControls.error.active.background};
        border-color: ${theme.colors.inputControls.error.active.border};
        box-shadow: 0 0 0 2px ${theme.colors.inputControls.error.active.shadow};

        &:after {
          background-color: ${theme.colors.inputControls.error.active.border};
        }
      }
    }

    &:checked + ${RadioIcon} {
      background-color: ${theme.colors.inputControls.checked.background};
      border-color: ${theme.colors.inputControls.checked.border};
      &:after {
        transform: translate(-50%, -50%) scale(1);
      }
    }

    &[data-field-error='true'] + ${RadioIcon} {
      background-color: ${theme.colors.inputControls.error.background};
      border-color: ${theme.colors.inputControls.error.border};

      &:after {
        background-color: ${theme.colors.inputControls.error.border};
      }
    }

    &:disabled + ${RadioIcon} {
      background-color: ${theme.colors.inputControls.disabled.background};
      border-color: ${theme.colors.inputControls.disabled.border};
      box-shadow: none;
      cursor: not-allowed;

      &:after {
        background-color: ${theme.colors.inputControls.disabled.icon};
      }
    }
    &:disabled ~ ${RadioContent} {
      cursor: not-allowed;
    }
  `
)

const getRadioBodyMargin = (
  theme: ThemeType,
  hasTouchArea?: boolean,
  labelSize?: TextVariant
) => {
  if (!hasTouchArea) return `0px`

  if (hasTouchArea && labelSize === 'bodyM')
    return `${theme.layout.spacing.s300}px 0px`

  if (hasTouchArea && labelSize === 'bodyS')
    return `${theme.layout.spacing.s400}px 0px 0px`

  return `${theme.layout.spacing.s100}px`
}

export const RadioBody = styled.label<
  Pick<Props, 'hasTouchArea' | 'fullWidth' | 'labelSize'>
>(
  ({theme, hasTouchArea, fullWidth, labelSize}) => css`
    display: flex;
    position: relative;
    padding: ${theme.layout.spacing.s100}px;
    margin: ${getRadioBodyMargin(theme, hasTouchArea, labelSize)};

    ${fullWidth && `flex: 1;`}

    ${linariaMq.desktopXs} {
      &:hover {
        ${RadioInput}:not(:disabled):not(:active) {
          & + ${RadioIcon} {
            border-color: ${theme.colors.inputControls.default.hover.border};
            box-shadow: 0 0 0 2px
              ${theme.colors.inputControls.default.hover.shadow};
          }

          &:checked + ${RadioIcon} {
            background-color: ${theme.colors.inputControls.checked.hover
              .background};
            border-color: ${theme.colors.inputControls.checked.hover.border};
            box-shadow: 0 0 0 2px
              ${theme.colors.inputControls.checked.hover.shadow};
          }

          &[data-field-error='true'] + ${RadioIcon} {
            background-color: ${theme.colors.inputControls.error.hover
              .background};
            border-color: ${theme.colors.inputControls.error.hover.border};
            box-shadow: 0 0 0 2px
              ${theme.colors.inputControls.error.hover.shadow};

            &:after {
              background-color: ${theme.colors.inputControls.error.hover
                .border};
            }
          }
        }
      }
    }

    @supports selector(:has(input:disabled)) {
      cursor: pointer;
      :has(input:disabled) {
        cursor: not-allowed;
      }
    }
  `
)

export const RadioContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: ${LABEL_MARGIN_LEFT}px;
`

export const StyledText = styled(Text)`
  line-height: ${RADIO_SIZE}px;
`

export const Radio = ({
  checked,
  value,
  name,
  dataId,
  onChange,
  label,
  disabled,
  fullWidth = false,
  hasError = false,
  hasTouchArea = false,
  className,
  labelSize = 'bodyS'
}: Props) => (
  <RadioElement className={className}>
    <RadioBody
      hasTouchArea={hasTouchArea}
      fullWidth={fullWidth}
      labelSize={labelSize}
    >
      <RadioInput
        type="radio"
        name={name}
        data-id={dataId}
        checked={checked}
        value={value}
        disabled={disabled}
        data-field-error={hasError}
        onChange={onChange}
      />
      <RadioIcon />
      {Boolean(label) && (
        <RadioContent>
          <StyledText variant={labelSize} as="span">
            {label}
          </StyledText>
        </RadioContent>
      )}
    </RadioBody>
  </RadioElement>
)

import React, {forwardRef, ReactNode} from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {toggle} from 'opticks'
import {SizesType} from 'types/Sizes'
import {CSSRuleType, ThemeType} from 'types/Theme'

import {hexToRGBAString} from '@daedalus/core/src/utils/string'

import {linariaMq} from '../../../utils/breakpoints'
import {NewStyleButton} from '../experimental/NewStyleButton'
import {LoadingBullets} from './LoadingBullets'

export type Variant =
  | 'primary'
  | 'secondary'
  | 'special'
  | 'warning'
  | 'quiet'
  | 'transparent'
  | 'inverse'
  // added as part of b4f4f3cc-website-restyle-v3
  | 'primaryGradient'
  | 'secondaryGradient'

export type ButtonSize = Exclude<SizesType, 'xs' | 'xxl'>

export interface Props {
  /** Identify the element for selection in integration tests, FullStory, etc. */
  dataId?: string
  /** Button content */
  children?: React.ReactNode
  /** Pass through classname to allow styles overrides */
  className?: string
  /** Button style */
  variant?: Variant
  /** Whether the button is in the loading state */
  loading?: boolean
  /** The button click handler */
  onClick?: (e: React.SyntheticEvent) => void
  /** The button size */
  size?: ButtonSize
  /** The HTML button type */
  type?: 'submit' | 'reset' | 'button' | undefined
  /** Whether the button full width of parent */
  fullWidth?: boolean
  /** Whether the button is  disabled */
  disabled?: boolean
  /** Whether the button is  rounded */
  isRounded?: boolean
  /** An Icon to display on the left side of the input for LTR languages. Inverted on RTL */
  iconStart?: ReactNode
  /** An Icon to display on the right side of the input for LTR languages. Inverted on RTL */
  iconEnd?: ReactNode
  /** To add drop shadow styling */
  isFloating?: boolean
  /** Use larger touch area */
  hasTouchArea?: boolean
  form?: string
  /** Optional aria-label for screen-readers, used for icon-only buttons */
  ariaLabel?: string
}

export interface MakeStyledButtonProps {
  fontColor?: string
  backgroundColor?: string
  hoverBackgroundColor?: string
  activeBackgroundColor?: string
  borderColor?: string
  hoverBorderColor?: string
  activeBorderColor?: string
  // Added as a part of b4f4f3cc-website-restyle-v3
  variant?: Variant
  shadow?: CSSRuleType
}

export interface StyleProps {
  variant?: Variant
  disabled?: boolean
  disabledStyle?: boolean
  fullWidth?: boolean
  hasChildren?: boolean
  size: ButtonSize
  color?: string
  delay?: string
  isRounded?: boolean
  hasStartIcon?: boolean
  hasEndIcon?: boolean
  isFloating?: boolean
  hasTouchArea?: boolean
  loading?: boolean
}

interface ButtonSizeMap {
  size: number
  sizeWithTouchArea: number
  basePadding: number
  horizontalPadding?: number
}

export const buttonSizesMap: Record<ButtonSize, ButtonSizeMap> = {
  sm: {
    size: 26,
    sizeWithTouchArea: 44,
    basePadding: 4
  },
  md: {
    size: 34,
    sizeWithTouchArea: 44,
    basePadding: 6
  },
  lg: {
    size: 42,
    sizeWithTouchArea: 44,
    basePadding: 8
  },
  xl: {
    size: 48,
    sizeWithTouchArea: 48,
    basePadding: 8,
    horizontalPadding: 16
  }
}

export const withBaseStyles = () => {
  return css`
    border: 0;
    margin: 0;
    padding: 0;
    user-select: none;
    outline: none;
    position: relative;
    text-decoration: none;
    background: none;
    color: inherit;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  `
}

export const makeStyledButton = ({
  fontColor,
  backgroundColor,
  hoverBackgroundColor,
  activeBackgroundColor,
  borderColor,
  hoverBorderColor,
  activeBorderColor
}: MakeStyledButtonProps) => css`
  ${ButtonStyledContainer} {
    ${!!fontColor && `color: ${fontColor}`};
    ${!!backgroundColor && `background-color: ${backgroundColor}`};
    border: 1px solid ${borderColor ? borderColor : ''};
  }

  ${linariaMq.desktopXs} {
    &:hover,
    &:focus {
      ${ButtonStyledContainer} {
        ${!!hoverBackgroundColor &&
        `background-color: ${hoverBackgroundColor}`};
        border: 1px solid ${hoverBorderColor ? hoverBorderColor : ''};
      }
    }
  }

  &:active {
    ${ButtonStyledContainer} {
      ${!!activeBackgroundColor &&
      `background-color: ${activeBackgroundColor}`};
      border: 1px solid ${activeBorderColor ? activeBorderColor : ''};
    }
  }
`

const colorPrimary = ({theme}: {theme: ThemeType}) => {
  const primaryColors = theme.colors.button.primary

  return makeStyledButton({
    fontColor: primaryColors.content,
    backgroundColor: primaryColors.background,
    hoverBackgroundColor: primaryColors.hover.background,
    activeBackgroundColor: primaryColors.active.background,
    borderColor: primaryColors.border,
    hoverBorderColor: primaryColors.hover.border,
    activeBorderColor: primaryColors.active.border
  })
}

const colorSecondary = ({theme}: {theme: ThemeType}) => {
  const secondaryColors = theme.colors.button.secondary

  return makeStyledButton({
    fontColor: secondaryColors.content,
    backgroundColor: secondaryColors.background,
    hoverBackgroundColor: secondaryColors.hover.background,
    activeBackgroundColor: secondaryColors.active.background,
    borderColor: secondaryColors.border,
    hoverBorderColor: secondaryColors.hover.border,
    activeBorderColor: secondaryColors.active.border
  })
}

const colorInverse = ({theme}: {theme: ThemeType}) => {
  const inverseColors = theme.colors.button.inverse

  return makeStyledButton({
    fontColor: inverseColors.content,
    backgroundColor: inverseColors.background,
    hoverBackgroundColor: inverseColors.hover.background,
    activeBackgroundColor: inverseColors.active.background,
    borderColor: inverseColors.border,
    hoverBorderColor: inverseColors.hover.border,
    activeBorderColor: inverseColors.active.border
  })
}

const colorSpecial = ({theme}: {theme: ThemeType}) => {
  const specialColors = theme.colors.button.special

  return makeStyledButton({
    fontColor: specialColors.content,
    backgroundColor: specialColors.background,
    hoverBackgroundColor: specialColors.hover.background,
    activeBackgroundColor: specialColors.active.background,
    borderColor: specialColors.border,
    hoverBorderColor: specialColors.hover.border,
    activeBorderColor: specialColors.active.border
  })
}

const colorWarning = ({theme}: {theme: ThemeType}) => {
  const dangerColors = theme.colors.button.danger

  return makeStyledButton({
    fontColor: dangerColors.content,
    backgroundColor: dangerColors.background,
    hoverBackgroundColor: dangerColors.hover.background,
    activeBackgroundColor: dangerColors.active.background,
    borderColor: dangerColors.border,
    hoverBorderColor: dangerColors.hover.border,
    activeBorderColor: dangerColors.active.border
  })
}

const colorQuiet = ({theme}: {theme: ThemeType}) => {
  const quietColors = theme.colors.button.quiet

  return makeStyledButton({
    fontColor: quietColors.content,
    backgroundColor: quietColors.background,
    hoverBackgroundColor: quietColors.hover.background,
    activeBackgroundColor: quietColors.active.background,
    borderColor: quietColors.border,
    hoverBorderColor: quietColors.hover.border,
    activeBorderColor: quietColors.active.border
  })
}

const colorTransparent = ({theme}: {theme: ThemeType}) => {
  const transparentColors = theme.colors.button.transparent

  return makeStyledButton({
    fontColor: transparentColors.content,
    backgroundColor: transparentColors.background,
    hoverBackgroundColor: hexToRGBAString(
      transparentColors.hover.background,
      0.2
    ),
    activeBackgroundColor: hexToRGBAString(
      transparentColors.active.background,
      0.1
    ),
    borderColor: transparentColors.border,
    hoverBorderColor: transparentColors.border,
    activeBorderColor: transparentColors.border
  })
}

const colorDisabled = ({theme}: {theme: ThemeType}) => {
  const disabledColors = theme.colors.button.disabled

  return makeStyledButton({
    fontColor: disabledColors.content,
    backgroundColor: disabledColors.background,
    hoverBackgroundColor: disabledColors.background,
    activeBackgroundColor: disabledColors.background,
    borderColor: disabledColors.border,
    hoverBorderColor: disabledColors.border,
    activeBorderColor: disabledColors.border
  })
}

export const withColor = ({
  variant,
  disabledStyle
}: StyleProps & {theme: ThemeType}) => {
  if (disabledStyle) return colorDisabled

  switch (variant) {
    case 'inverse': {
      return colorInverse
    }
    case 'secondary': {
      return colorSecondary
    }
    case 'special': {
      return colorSpecial
    }
    case 'warning': {
      return colorWarning
    }
    case 'quiet': {
      return colorQuiet
    }
    case 'transparent': {
      return colorTransparent
    }
    case 'primary':
    default: {
      return colorPrimary
    }
  }
}

export const withTouchArea = ({hasTouchArea, size}: StyleProps) => {
  const {size: sizeNoTouchArea, sizeWithTouchArea} = buttonSizesMap[size]

  return css`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: ${hasTouchArea ? sizeWithTouchArea : sizeNoTouchArea}px;
    min-width: ${hasTouchArea ? sizeWithTouchArea : sizeNoTouchArea}px;
  `
}

export const withSize = ({size}: StyleProps) => {
  const {size: minSize, basePadding, horizontalPadding} = buttonSizesMap[size]

  return css`
    min-height: ${minSize}px;
    min-width: ${minSize}px;
    padding: ${horizontalPadding
      ? `${basePadding}px ${horizontalPadding}px`
      : `${basePadding}px`};
  `
}

export const withTextSize = ({
  theme,
  size
}: StyleProps & {theme: ThemeType}) => {
  const {typography} = theme

  switch (size) {
    case 'lg': {
      return css`
        ${typography.text.labelM};
      `
    }
    case 'xl': {
      return css`
        ${typography.text.labelM};
      `
    }
    case 'sm': {
      return css`
        ${typography.text.labelXS};
      `
    }
    case 'md':
    default: {
      return css`
        ${typography.text.labelS};
      `
    }
  }
}

export const withFullWidth = ({fullWidth}: StyleProps) => {
  if (fullWidth) {
    return css`
      width: 100%;

      ${ButtonStyledContainer} {
        flex-grow: 1;
      }
    `
  }

  return null
}

export const withRounded = ({
  theme,
  isRounded
}: StyleProps & {theme: ThemeType}) => {
  if (isRounded) {
    return css`
      border-radius: ${theme.layout.radius.rounded}px;
    `
  }

  return css`
    border-radius: ${theme.layout.radius.md}px;
  `
}

export const withDisabled = ({disabled}: StyleProps) => {
  if (disabled) {
    return css`
      cursor: not-allowed;
    `
  }

  return css`
    cursor: pointer;
  `
}

export const withFloatingShadow = ({
  theme,
  isFloating,
  disabledStyle
}: StyleProps & {theme: ThemeType}) =>
  isFloating && !disabledStyle
    ? css`
        box-shadow: ${theme.shadows.floating};
      `
    : null

const ButtonElement = styled.button(
  withBaseStyles,
  withTouchArea,
  withFullWidth,
  withColor,
  withDisabled
)

// This component name is used in the ButtonGroup component
export const ButtonStyledContainer = styled.div(
  () => css`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  withSize,
  withTextSize,
  withRounded,
  withFloatingShadow
)

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LabelWrapper = styled.div(
  ({size}: StyleProps) => css`
    padding: 0 ${buttonSizesMap[size].basePadding}px;
  `
)

export const AtlasButton = forwardRef(
  (
    {
      variant = 'primary',
      size = 'lg',
      loading = false,
      disabled = false,
      fullWidth = false,
      isRounded = false,
      className = '',
      dataId = '',
      type = 'button',
      onClick,
      children,
      iconStart,
      iconEnd,
      isFloating = false,
      hasTouchArea = false,
      form,
      ariaLabel
    }: Props,
    ref: React.ForwardedRef<HTMLButtonElement>
  ) => {
    const disabledState = loading || disabled
    const disabledStyle = disabled
    const hasChildren = Boolean(children)

    return (
      <ButtonElement
        ref={ref}
        data-id={dataId}
        className={className}
        variant={variant}
        size={size}
        type={type}
        disabled={disabledState}
        fullWidth={fullWidth}
        hasChildren={hasChildren}
        hasTouchArea={hasTouchArea}
        disabledStyle={disabledStyle}
        onClick={onClick}
        form={form}
        aria-label={ariaLabel}
      >
        <ButtonStyledContainer
          size={size}
          hasStartIcon={Boolean(iconStart)}
          hasEndIcon={Boolean(iconEnd)}
          hasChildren={hasChildren}
          isRounded={isRounded}
          isFloating={isFloating}
        >
          {loading ? (
            <LoadingBullets size={size} />
          ) : (
            <>
              {iconStart && <IconWrapper>{iconStart}</IconWrapper>}

              {children && <LabelWrapper size={size}>{children}</LabelWrapper>}

              {iconEnd && <IconWrapper>{iconEnd}</IconWrapper>}
            </>
          )}
        </ButtonStyledContainer>
      </ButtonElement>
    )
  }
)

// eslint-disable-next-line fp/no-mutation
AtlasButton.displayName = 'AtlasButton'

export const Button = forwardRef(
  (props: Props, ref: React.ForwardedRef<HTMLButtonElement>) => {
    return toggle(
      'b4f4f3cc-website-restyle-v3',
      () => <AtlasButton {...props} ref={ref} />,
      () => <NewStyleButton {...props} ref={ref} />
    )
  }
)

// eslint-disable-next-line fp/no-mutation
Button.displayName = 'Button'

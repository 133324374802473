import differenceInDays from 'date-fns/differenceInDays'
import parseISO from 'date-fns/parseISO'

import {
  Booking,
  Source as BookingSource
} from '../../api-types/bovio/response/booking'
import {
  getRefundType,
  RefundType
} from '../../offer/business/cancellationPenalties'
import {getDisplayPrice, getTotalPrice, Price} from '../../price/business/price'
import {BookingContext} from '../types/Events'

const getBookingContext = (
  bookingData: Booking | null
): BookingContext | null => {
  if (!bookingData) return null

  const {
    status,
    cancellationPenalties,
    rooms,
    confirmationId,
    providerConfirmationId,
    id,
    merchantOfRecord,
    checkIn,
    checkOut,
    expiresAt,
    source,
    prices,
    insertedAt: bookedAt
  } = bookingData

  const totalPrice =
    bookingData.prices && getTotalPrice(getDisplayPrice<Price>(prices))

  const numberOfRooms = rooms.length
  const numberOfDays = differenceInDays(parseISO(checkOut), parseISO(checkIn))
  const displayPricePerRoomPerNightValue =
    totalPrice && Number(totalPrice) / numberOfRooms / numberOfDays

  const isDealFreeze = source === BookingSource.DealFreeze
  const chargeType = source && [source]
  const paymentExpiresAt = {
    ...(isDealFreeze && expiresAt && {freezeExpiryAt: expiresAt})
  }

  return {
    status,
    cancellationPenalties,
    displayPricePerRoomPerNight: displayPricePerRoomPerNightValue,
    hasFreeCancellation:
      getRefundType(cancellationPenalties) === RefundType.freeCancellation,
    roomName: rooms[0]?.name || '',
    totalPrice,
    bookingId: confirmationId || '',
    providerConfirmationId,
    fhBookingId: id,
    merchantOfRecord: merchantOfRecord || '',
    ...(expiresAt && {paymentExpiryAt: paymentExpiresAt}),
    ...(chargeType && chargeType?.length > 0 && {chargeType}),
    ...(isDealFreeze && {bookedAt})
  }
}

export default getBookingContext

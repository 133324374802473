import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {RangeSizesType} from '../../../../../types/Sizes'
import {cssTheme} from '../../../../themes'

interface PropsType {
  /** The size of the bottom padding. Only spacing sizes from the theme are accepted */
  paddingBottom?: RangeSizesType

  /** The size of the left padding. Only spacing sizes from the theme are accepted */
  paddingLeft?: RangeSizesType

  /** The size of the right padding. Only spacing sizes from the theme are accepted */
  paddingRight?: RangeSizesType

  /** The size of the padding for all sides. Only spacing sizes from the theme are accepted. Each side will be overwritten if it is passed separately */
  padding?: RangeSizesType

  /** The size of the top padding. Only spacing sizes from the theme are accepted */
  paddingTop?: RangeSizesType

  /** The size of the horizontal padding. Only spacing sizes from the theme are accepted */
  paddingX?: RangeSizesType

  /** The size of the vertical padding. Only spacing sizes from the theme are accepted */
  paddingY?: RangeSizesType
}

// Helper function to determine padding
const resolvePadding = (
  specificPadding: RangeSizesType | undefined,
  axisPadding: RangeSizesType | undefined,
  defaultPadding: RangeSizesType
): string => {
  return cssTheme.layout.spacing[
    specificPadding || axisPadding || defaultPadding
  ]
}

export const ContentWrapper = styled.div<PropsType>(
  ({
    padding = 's000',
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingX,
    paddingY
  }) => css`
    padding-bottom: ${resolvePadding(paddingBottom, paddingY, padding)};
    padding-left: ${resolvePadding(paddingLeft, paddingX, padding)};
    padding-right: ${resolvePadding(paddingRight, paddingX, padding)};
    padding-top: ${resolvePadding(paddingTop, paddingY, padding)};
  `
)

export default ContentWrapper
